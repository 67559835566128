<template>
  <div :class="$style.root">
    <Profile v-if="profile" :values="profile.values" :master-user="profile.masterUser" :settings="profile.settings" />
  </div>
</template>

<script>
import Profile from '@/components/user/Profile/Profile';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ProfileView',
  components: {
    Profile,
  },
  computed: {
    ...mapGetters({
      profile: 'user/profile',
    }),
  },
  methods: {
    ...mapActions({
      fetchProfileDetail: 'user/fetchProfileDetail',
    }),
  },
  mounted() {
    this.fetchProfileDetail();
  },
}
</script>

<style module lang="scss">
.root {

}
</style>
